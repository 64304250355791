import React, { lazy } from "react";
const PublicComponents = React.lazy(() => import("./PublicComponents"));
const PrivateComponents = React.lazy(() => import("./PrivateComponents"));
const routes = [
  //AUTH ROUTE
  {
    path: "/login",
    exact: true,
    type: "auth",
    title: "Авторизация",
    component: () => <PublicComponents name="Login" />,
  },
  {
    path: "/register",
    exact: true,
    type: "auth",
    title: "Регистрация",
    component: () => <PublicComponents name="Register" />,
  },
  {
    path: "/forgot",
    exact: true,
    type: "auth",
    title: "Забыли пароль",
    component: () => <PublicComponents name="Forgot" />,
  },
  {
    path: "/reset/:token",
    isDynamic: true,
    exact: true,
    type: "auth",
    title: "Восстановление пароля",
    component: () => <PublicComponents name="Reset" />,
  },
  //AUTH ROUTE END
  //PUBLIC ROUTE
  {
    path: "/page/:slug",
    exact: true,
    type: "public",
    component: () => <PrivateComponents name="Page" />,
  },
  {
    path: "/",
    exact: true,
    type: "public",
    component: () => <PublicComponents name="Main" />,
  },
  {
    path: "/faq",
    exact: true,
    type: "public",
    component: () => <PublicComponents name="FAQ" />,
  },
  {
    path: "/cargo",
    exact: true,
    type: "public",
    component: () => <PublicComponents name="Cargo" />,
  },
  {
    path: "/search-offer",
    exact: true,
    type: "public",
    component: () => <PublicComponents name="SearchPage" type="offer" />,
  },
  {
    path: "/search-order",
    exact: true,
    type: "public",
    component: () => <PublicComponents name="SearchPage" type="order" />,
  },
  {
    path: "/tariffs",
    exact: true,
    type: "public",
    component: () => <PublicComponents name="TarifPage" />,
  },
  {
    path: "/download-app",
    exact: true,
    type: "public",
    component: () => <PublicComponents name="Application" />,
  },
  {
    path: "/carrier",
    exact: true,
    type: "public",
    component: () => <PublicComponents name="Carrier" />,
  },
  {
    path: "/questions/:slug",
    exact: true,
    type: "public",
    component: () => <PrivateComponents name="Questions" />,
  },
  //PUBLIC ROUTE END
  //PRIVATE ROUTE
  {
    path: "/profile/security",
    exact: true,
    type: "private",
    title: "Безопасность",
    component: () => <PrivateComponents name="Profile" step={2} />,
  },
  {
    path: "/profile/info",
    exact: true,
    type: "private",
    title: "Личная информация",
    component: () => <PrivateComponents name="Profile" step={1} />,
  },
  {
    path: "/lk/questions/:slug",
    exact: true,
    type: "private",
    title: "Вопрос",
    forseTitle: true,
    component: () => <PrivateComponents name="Questions" isPrivate={true} />,
  },
  {
    path: "/notifications",
    exact: true,
    type: "private",
    title: "Уведомления",
    component: () => <PrivateComponents name="Notifications" />,
  },
  {
    path: "/messages-by-order",
    exact: true,
    type: "private",
    tab: "order",
    title: "Сообщения по заказам, предложениям",
    component: (props) => <PrivateComponents name="Messages" {...props} />,
  },
  {
    path: "/messages",
    exact: true,
    type: "private",
    tab: "all",
    title: "Сообщения",
    component: (props) => <PrivateComponents name="Messages" {...props} />,
  },
  {
    path: "/messages-users",
    exact: true,
    type: "private",
    tab: "user",
    title: "Сообщения пользователей",
    component: (props) => <PrivateComponents name="Messages" {...props} />,
  },
  {
    path: "/dialog/:id",
    exact: true,
    type: "private",
    lkHeight: true,
    dialogType: 2,
    title: "Сообщения пользователей",
    component: (props) => <PrivateComponents name="Dialog" {...props} />,
  },
  {
    path: "/dialog-order/:order/:id",
    exact: true,
    type: "private",
    lkHeight: true,
    dialogType: 2,
    title: "Сообщения пользователей",
    component: (props) => <PrivateComponents name="DialogOrder" {...props} />,
  },
  {
    path: "/dialog-order/:id",
    exact: true,
    type: "private",
    lkHeight: true,
    dialogType: 1,
    title: "Сообщения пользователей",
    component: (props) => <PrivateComponents name="Dialog" {...props} />,
  },
  {
    path: "/mytarif",
    exact: true,
    type: "private",
    title: "Мои тариф",
    role: "carrier",
    component: () => <PrivateComponents name="TarifLk" />,
  },
  {
    path: "/notifications-settings",
    exact: true,
    type: "private",
    title: "Настройки уведомлений",
    component: () => <PrivateComponents name="NotificationsSettings" />,
  },
  {
    path: "/my-orders",
    exact: true,
    type: "private",
    title: "Мои заказы",
    typeArticle: "order",
    statusArticle: [1, 2, 3, 4, 5, 6, 7],
    role: "cargo",
    component: (props) => <PrivateComponents name="MyArticles" {...props} />,
  },
  {
    path: "/my-orders-open",
    exact: true,
    type: "private",
    title: "Открытые заказы",
    typeArticle: "order",
    statusArticle: [1, 2],
    role: "cargo",
    component: (props) => <PrivateComponents name="MyArticles" {...props} />,
  },
  {
    path: "/my-orders-working",
    exact: true,
    type: "private",
    title: "Заказы в работе",
    typeArticle: "order",
    statusArticle: [3, 4],
    role: "cargo",
    component: (props) => <PrivateComponents name="MyArticles" {...props} />,
  },
  {
    path: "/my-orders-completed",
    exact: true,
    type: "private",
    typeArticle: "order",
    statusArticle: [5, 6],
    title: "Закрытые заказы",
    role: "cargo",
    component: (props) => <PrivateComponents name="MyArticles" {...props} />,
  },
  {
    path: "/autopay",
    exact: true,
    type: "private",
    title: "Настройка автоплатежа",
    role: "carrier",
    component: (props) => <PrivateComponents name="AutoPay" {...props} />,
  },
  {
    path: "/my-orders-deleted",
    exact: true,
    type: "private",
    typeArticle: "order",
    statusArticle: [7],
    title: "Корзина",
    role: "cargo",
    component: (props) => <PrivateComponents name="MyArticles" {...props} />,
  },
  {
    path: "/order/:id",
    exact: true,
    type: "common",
    title: "Заказ #0000",
    forseTitle: true,
    component: (props) => (
      <PrivateComponents name="ArticlePage" type="order" {...props} />
    ),
  },
  {
    path: "/edit-order/:id",
    exact: true,
    type: "private",
    title: "Редактирование заказа",
    forseTitle: true,
    role: "cargo",
    component: (props) => <PrivateComponents name="NewOrder" {...props} />,
  },
  {
    path: "/offer/:id",
    exact: true,
    type: "common",
    title: "Предложение #0000",
    forseTitle: true,
    component: (props) => (
      <PrivateComponents name="ArticlePage" type="offer" {...props} />
    ),
  },
  {
    path: "/edit-offer/:id",
    exact: true,
    type: "private",
    title: "Редактирование предложения",
    forseTitle: true,
    role: "carrier",
    component: (props) => <PrivateComponents name="NewOffer" {...props} />,
  },
  {
    path: "/order-create",
    exact: true,
    type: "private",
    title: "Новый заказ",
    role: "cargo",
    component: (props) => <PrivateComponents name="NewOrder" {...props} />,
  },
  {
    path: "/taken-orders",
    exact: true,
    type: "private",
    typeArticle: "order",
    statusArticle: [3, 4, 5, 6],
    title: "Взятые заказы",
    role: "carrier",
    component: (props) => (
      <PrivateComponents name="TakingArticles" {...props} />
    ),
  },
  {
    path: "/my-offers",
    exact: true,
    type: "private",
    typeArticle: "offer",
    statusArticle: [1, 2, 3, 4, 5, 6, 7],
    title: "Мои предложения",
    role: "carrier",
    component: (props) => <PrivateComponents name="MyArticles" {...props} />,
  },
  {
    path: "/my-offers-open",
    exact: true,
    type: "private",
    typeArticle: "offer",
    statusArticle: [1, 2],
    title: "Открытые предложения",
    role: "carrier",
    component: (props) => <PrivateComponents name="MyArticles" {...props} />,
  },
  {
    path: "/my-offers-working",
    exact: true,
    type: "private",
    typeArticle: "offer",
    statusArticle: [3, 4],
    title: "Предложения в работе",
    role: "carrier",
    component: (props) => <PrivateComponents name="MyArticles" {...props} />,
  },
  {
    path: "/my-offers-completed",
    exact: true,
    type: "private",
    typeArticle: "offer",
    statusArticle: [5, 6],
    title: "Закрытые предложения",
    role: "carrier",
    component: (props) => <PrivateComponents name="MyArticles" {...props} />,
  },
  {
    path: "/my-offers-deleted",
    exact: true,
    type: "private",
    typeArticle: "offer",
    statusArticle: [7],
    title: "Корзина",
    role: "carrier",
    component: (props) => <PrivateComponents name="MyArticles" {...props} />,
  },
  {
    path: "/taken-offers",
    exact: true,
    type: "private",
    typeArticle: "offer",
    statusArticle: [3, 4, 5, 6],
    title: "Взятые предложения",
    role: "cargo",
    component: (props) => (
      <PrivateComponents name="TakingArticles" {...props} />
    ),
  },
  {
    path: "/offer-create",
    exact: true,
    type: "private",
    title: "Новое предложение",
    role: "carrier",
    component: (props) => <PrivateComponents name="NewOffer" {...props} />,
  },
  {
    path: "/create-template-auto",
    exact: true,
    type: "private",
    title: "Новый шаблон авто",
    role: "carrier",
    component: (props) => (
      <PrivateComponents name="CreateTemplateAuto" {...props} />
    ),
  },
  {
    path: "/edit-template-auto/:id",
    exact: true,
    type: "private",
    title: "Редактирование шаблона Авто",
    role: "carrier",
    component: (props) => (
      <PrivateComponents name="CreateTemplateAuto" {...props} />
    ),
  },
  {
    path: "/mytemplate-auto",
    exact: true,
    type: "private",
    title: "Мои шалоны авто",
    role: "carrier",
    component: (props) => (
      <PrivateComponents name="MyTemplateAuto" {...props} />
    ),
  },
  {
    path: "/user/:id",
    exact: true,
    type: "public",
    title: "Профиль пользователя",
    component: (props) => <PrivateComponents name="User" {...props} />,
  },
  {
    path: "/reviews",
    exact: true,
    type: "private",
    title: "Отзывы",
    component: (props) => <PrivateComponents name="Reviwes" {...props} />,
  },
  {
    path: "/support",
    exact: true,
    type: "private",
    title: "Техподдержка",
    component: (props) => <PrivateComponents name="Support" {...props} />,
  },
  {
    path: "/geo-detect",
    exact: true,
    type: "private",
    title: "Отслеживание",
    role: "cargo",
    component: (props) => <PrivateComponents name="GeoDetect" {...props} />,
  },
];

export default routes;
