// User
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_EDIT = "USER_EDIT";
export const USER_SET_TARIFF = "USER_SET_TARIFF";
export const USER_UNSET_TARIFF = "USER_UNSET_TARIFF";
export const USER_SET_GEOLOCATION_ERROR = "USER_SET_GEOLOCATION_ERROR";
export const USER_SET_LOCATION_ID = "USER_SET_GEOLOCATION_ERROR";
export const USER_SET_BAN = "USER_SET_BAN";
export const USER_SET_CANCEL_BAN = "USER_SET_CANCEL_BAN";
export const USER_SET_MODERATION_SUCCESS = "USER_SET_MODERATION_SUCCESS";
export const USER_SET_MODERATION_FAIL = "USER_SET_MODERATION_FAIL";
export const USER_SET_NEED_LOCATION = "USER_SET_NEED_LOCATION";
export const USER_TOOGLE_AUTOPAY = "USER_TOOGLE_AUTOPAY";
//SETTINGS
export const GET_SETTINGS = "GET_SETTINGS";
// Dialogs
export const DIALOGS_GET = "DIALOGS_GET";
export const DIALOGS_ADD = "DIALOGS_ADD";
export const DIALOGS_ADD_MESSAGE = "DIALOGS_ADD_MESSAGE";
export const DIALOGS_SUCCESS_MESSAGE = "DIALOGS_SUCCESS_MESSAGE";
export const DIALOGS_ERROR_MESSAGE = "DIALOGS_ERROR_MESSAGE";
export const DIALOGS_READ_MESSAGES = "DIALOGS_READ_MESSAGES";
export const DIALOG_LOAD = "DIALOG_LOAD";
export const DIALOGS_SET_TYPER = "DIALOGS_SET_TYPER";
export const DIALOGS_REMOVE_TYPER = "DIALOGS_REMOVE_TYPER";
export const DIALOG_SET_LOADING = "DIALOG_SET_LOADING";
export const DIALOGS_LOAD_MESSAGES = "DIALOGS_LOAD_MESSAGES";
export const DIALOGS_UPDATE_ONLINE = "DIALOGS_UPDATE_ONLINE";
export const DIALOGS_DELETE_MESSAGE = "DIALOGS_DELETE_MESSAGE";

// DialogsOrder
export const DIALOGS_SET_NOREAD = "DIALOGS_SET_NOREAD";
export const DIALOGSORDER_GET = "DIALOGSORDER_GET";
export const DIALOGSORDER_ADD = "DIALOGSORDER_ADD";
export const DIALOGSORDER_ADD_MESSAGE = "DIALOGSORDER_ADD_MESSAGE";
export const DIALOGSORDER_SUCCESS_MESSAGE = "DIALOGSORDER_SUCCESS_MESSAGE";
export const DIALOGSORDER_ERROR_MESSAGE = "DIALOGSORDER_ERROR_MESSAGE";
export const DIALOGSORDER_READ_MESSAGES = "DIALOGSORDER_READ_MESSAGES";
export const DIALOGORDER_LOAD = "DIALOGORDER_LOAD";
export const DIALOGSORDER_SET_TYPER = "DIALOGSORDER_SET_TYPER";
export const DIALOGSORDER_REMOVE_TYPER = "DIALOGSORDER_REMOVE_TYPER";
export const DIALOGORDER_SET_LOADING = "DIALOGORDER_SET_LOADING";
export const DIALOGSORDER_LOAD_MESSAGES = "DIALOGSORDER_LOAD_MESSAGES";
export const DIALOGSORDER_UPDATE_ONLINE = "DIALOGSORDER_UPDATE_ONLINE";
export const DIALOGSORDER_DELETE_MESSAGE = "DIALOGSORDER_DELETE_MESSAGE";
export const DIALOGSORDER_ADD_MESSAGE_COUNT = "DIALOGSORDER_ADD_MESSAGE_COUNT";
export const DIALOGS_ADD_MESSAGE_COUNT = "DIALOGS_ADD_MESSAGE_COUNT";
export const DIALOGSORDER_LOAD = "DIALOGSORDER_LOAD";
export const DIALOGS_LOAD = "DIALOGS_LOAD";
export const DIALOGSALL_LOAD = "DIALOGSALL_LOAD";
export const DIALOGSALL_SET_LOADING = "DIALOGSALL_SET_LOADING";
export const DIALOGS_SET_LOADING = "DIALOGS_SET_LOADING";
export const DIALOGSORDER_SET_LOADING = "DIALOGSORDER_SET_LOADING";
export const DIALOGSALL_ADD_MESSAGE = "DIALOGSALL_ADD_MESSAGE";
export const DIALOGSALL_ADD = "DIALOGSALL_ADD";

//DIALOGSALL
export const DIALOGSALL_GET = "DIALOGSALL_GET";

// Users
export const USERS_GET = "USERS_GET";
export const USERS_ADD = "USERS_ADD";
//Articles
export const ARTICLES_MY_ALL_GET = "ARTICLES_MY_ALL_GET";
export const ARTICLES_MY_ALL_SET_LOADING = "ARTICLES_MY_ALL_SET_LOADING";
export const ARTICLES_MY_ALL_LOAD = "ARTICLES_MY_ALL_LOAD";
export const ARTICLES_MY_GET = "ARTICLES_MY_GET";
export const ARTICLES_MY_LOAD = "ARTICLES_MY_LOAD";
export const ARTICLES_MY_CREATE = "ARTICLES_MY_CREATE";
export const ARTICLES_MY_CREATE_COUNT = "ARTICLES_MY_CREATE_COUNT";
export const ARTICLES_MY_CREATE_REPLACE = "ARTICLES_MY_CREATE_REPLACE";
export const ARTICLES_MY_UPDATE = "ARTICLES_MY_UPDATE";
export const ARTICLES_MY_SET_LOADING = "ARTICLES_MY_SET_LOADING";
export const ARTICLES_MY_SET_COUNT = "ARTICLES_MY_SET_COUNT";
export const ARTICLE_MY_DELETE_FROM_STATUS = "ARTICLE_MY_DELETE_FROM_STATUS";
export const ARTICLE_MY_REVIEW_UPDATE = "ARTICLE_MY_REVIEW_UPDATE";
export const ARTICLE_MY_REVIEW_CREATE = "ARTICLE_MY_REVIEW_CREATE";
export const ARTICLES_MY_CURRENT_LOAD = "ARTICLES_MY_CURRENT_LOAD";
export const ARTICLE_SET_REQUEST = "ARTICLE_SET_REQUEST";
export const ARTICLE_UPDATE_REQUEST = "ARTICLE_UPDATE_REQUEST";
export const ARTICLE_DELETE_REQUEST = "ARTICLE_DELETE_REQUEST";
export const ARTICLES_MY_DELETE_EXECUTOR = "ARTICLES_MY_DELETE_EXECUTOR";
export const ARTICLES_MY_SET_EXECUTOR = "ARTICLES_MY_SET_EXECUTOR";
export const ARTICLES_MY_CURRENT_UPDATE = "ARTICLES_MY_CURRENT_UPDATE";
export const ARTICLE_SET_ME_REQUEST = "ARTICLE_SET_ME_REQUEST";
export const ARTICLE_UPDATE_ME_REQUEST = "ARTICLE_UPDATE_ME_REQUEST";
export const ARTICLE_REMOVE_ME_REQUEST = "ARTICLE_REMOVE_ME_REQUEST";
export const ARTICLE_MY_UPDATE_STATUS = "ARTICLE_MY_UPDATE_STATUS";
export const ARTICLE_MY_SET_DELIVERED = "ARTICLE_MY_SET_DELIVERED";

//TakingArticle
export const ARTICLE_TAKING_SET_DELIVERED = "ARTICLE_TAKING_SET_DELIVERED";
export const ARTICLE_TAKING_UPDATE_STATUS = "ARTICLE_TAKING_UPDATE_STATUS";
export const ARTICLES_TAKING_ALL_GET = "ARTICLES_TAKING_ALL_GET";
export const ARTICLES_TAKING_ALL_SET_LOADING =
  "ARTICLES_TAKING_ALL_SET_LOADING";
export const ARTICLES_TAKING_ALL_LOAD = "ARTICLES_TAKING_ALL_LOAD";
export const ARTICLES_TAKING_SET_LOADING = "ARTICLES_TAKING_SET_LOADING";
export const ARTICLES_TAKING_GET = "ARTICLES_TAKING_GET";
export const ARTICLES_TAKING_LOAD = "ARTICLES_TAKING_LOAD";
export const ARTICLES_TAKING_APPEND = "ARTICLES_TAKE_APPEND";
export const ARTICLES_TAKING_UPDATE = "ARTICLES_TAKE_UPDATE";
export const ARTICLES_TAKING_SET_COUNT = "ARTICLES_TAKE_SET_COUNT";
export const ARTICLE_TAKING_DELETE_FROM_STATUS =
  "ARTICLE_TAKING_DELETE_FROM_STATUS";
export const ARTICLES_TAKING_CREATE_COUNT = "ARTICLES_TAKING_CREATE_COUNT";
export const ARTICLES_TAKING_CREATE = "ARTICLES_TAKING_CREATE";
export const ARTICLES_TAKING_CREATE_REPLACE = "ARTICLES_TAKING_CREATE_REPLACE";
export const ARTICLES_MY_ALL_LOAD_END = "ARTICLES_MY_ALL_LOAD_END";
export const ARTICLES_TAKING_ALL_LOAD_END = "ARTICLES_TAKING_ALL_LOAD_END";
export const ARTICLE_TAKING_REVIEW_CREATE = "ARTICLE_TAKING_REVIEW_CREATE";
export const ARTICLE_TAKING_REVIEW_UPDATE = "ARTICLE_TAKING_REVIEW_UPDATE";
export const ARTICLES_TAKING_DELETE_EXECUTOR =
  "ARTICLES_TAKING_DELETE_EXECUTOR";
export const ARTICLES_TAKING_SET_EXECUTOR = "ARTICLES_TAKING_SET_EXECUTOR";
//GEOARTICLES
export const GEOARTICLES_GET = "GEOARTICLES_GET";
export const GEOARTICLE_SET_LOCATION = "GEOARTICLE_SET_LOCATION";
export const GEOARTICLES_ADD = "GEOARTICLES_ADD";
export const GEOARTICLES_DELETE = "GEOARTICLES_DELETE";
// Notifications
export const NOTIFICATIONS_ALL_GET = "NOTIFICATIONS_ALL_GET";
export const NOTIFICATIONS_ALL_LOAD = "NOTIFICATIONS_ALL_LOAD";
export const NOTIFICATIONS_ALL_LOADING = "NOTIFICATIONS_ALL_LOADING";
export const NOTIFICATIONS_ALL_ADD = "NOTIFICATIONS_ALL_ADD";
export const NOTIFICATIONS_ALL_READ = "NOTIFICATIONS_ALL_READ";
export const NOTIFICATIONS_ALL_SET_NO_READ = "NOTIFICATIONS_ALL_SET_NO_READ";

export const NOTIFICATIONS_OFFERS_GET = "NOTIFICATIONS_OFFERS_GET";
export const NOTIFICATIONS_OFFERS_LOAD = "NOTIFICATIONS_OFFERS_LOAD";
export const NOTIFICATIONS_OFFERS_LOADING = "NOTIFICATIONS_OFFERS_LOADING";
export const NOTIFICATIONS_OFFERS_ADD = "NOTIFICATIONS_OFFERS_ADD";
export const NOTIFICATIONS_OFFERS_READ = "NOTIFICATIONS_OFFERS_READ";
export const NOTIFICATIONS_OFFERS_SET_NO_READ =
  "NOTIFICATIONS_OFFERS_SET_NO_READ";

export const NOTIFICATIONS_ORDERS_GET = "NOTIFICATIONS_ORDERS_GET";
export const NOTIFICATIONS_ORDERS_LOAD = "NOTIFICATIONS_ORDERS_LOAD";
export const NOTIFICATIONS_ORDERS_LOADING = "NOTIFICATIONS_ORDERS_LOADING";
export const NOTIFICATIONS_ORDERS_ADD = "NOTIFICATIONS_ORDERS_ADD";
export const NOTIFICATIONS_ORDERS_READ = "NOTIFICATIONS_ORDERS_READ";
export const NOTIFICATIONS_ORDERS_SET_NO_READ =
  "NOTIFICATIONS_ORDERS_SET_NO_READ";

export const NOTIFICATIONS_SYSTEM_GET = "NOTIFICATIONS_SYSTEM_GET";
export const NOTIFICATIONS_SYSTEM_LOAD = "NOTIFICATIONS_SYSTEM_LOAD";
export const NOTIFICATIONS_SYSTEM_LOADING = "NOTIFICATIONS_SYSTEM_LOADING";
export const NOTIFICATIONS_SYSTEM_ADD = "NOTIFICATIONS_SYSTEM_ADD";
export const NOTIFICATIONS_SYSTEM_READ = "NOTIFICATIONS_SYSTEM_READ";
export const NOTIFICATIONS_SYSTEM_SET_NO_READ =
  "NOTIFICATIONS_SYSTEM_SET_NO_READ";

export const NOTIFICATIONS_TARRIFS_GET = "NOTIFICATIONS_TARRIFS_GET";
export const NOTIFICATIONS_TARRIFS_LOAD = "NOTIFICATIONS_TARRIFS_LOAD";
export const NOTIFICATIONS_TARRIFS_LOADING = "NOTIFICATIONS_TARRIFS_LOADING";
export const NOTIFICATIONS_TARRIFS_ADD = "NOTIFICATIONS_TARRIFS_ADD";
export const NOTIFICATIONS_TARRIFS_READ = "NOTIFICATIONS_TARRIFS_READ";
export const NOTIFICATIONS_TARRIFS_SET_NO_READ =
  "NOTIFICATIONS_TARRIFS_SET_NO_READ";
export const NOTIFICATIONS_NOREAD_GET = "NOTIFICATIONS_NOREAD_GET";
export const NOTIFICATIONS_NOREAD_ADD = "NOTIFICATIONS_NOREAD_ADD";
export const NOTIFICATIONS_SET_COUNT = "NOTIFICATIONS_SET_COUNT";
export const NOTIFICATIONS_READ_ALL = "NOTIFICATIONS_READ_ALL";

//REVIEW
export const REVIEWS_MY_GET = "REVIEWS_MY_GET";
export const REVIEWS_ME_GET = "REVIEWS_ME_GET";
export const REVIEWS_ME_CREATE = "REVIEWS_ME_CREATE";
export const REVIEWS_MY_CREATE = "REVIEWS_MY_CREATE";
export const REVIEWS_ME_UPDATE = "REVIEWS_ME_UPDATE";
export const REVIEWS_MY_UPDATE = "REVIEWS_MY_UPDATE";
export const REVIEWS_MY_SET_LOADING = "REVIEWS_MY_SET_LOADING";
export const REVIEWS_ME_SET_LOADING = "REVIEWS_ME_SET_LOADING";
export const REVIEWS_MY_LOAD = "REVIEWS_MY_LOAD";
export const REVIEWS_ME_LOAD = "REVIEWS_ME_LOAD";
